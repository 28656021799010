<template>
  <div class="register">
    <div class="login-main">
      <el-image class="main-left" :src="require(`../../assets/image/loginLeft.png`)" fit="contain"></el-image>
      <div class="main-right">
        <div class="right-center">
          <div class="login-title">
            <div class="title-ai">大嘴鸟AI注册</div>
          </div>
          <div class="login-form">
            <el-form ref="registerForm" :model="registerForm" :rules="rules">
              <div class="form-div">
                <div class="form-title m-r2">手机号</div>
                <el-form-item prop="phone">
                  <el-input class="form-input" type="text" v-model="registerForm.phone" placeholder="账号（手机号）" />
                </el-form-item>
              </div>
              <div class="form-div">
                <div class="form-title m-r3">密码</div>
                <el-form-item prop="password">
                  <el-input class="form-input" type="password" v-model="registerForm.password" placeholder="请输入密码" show-password />
                </el-form-item>
              </div>

              <div class="form-div">
                <div class="form-title m-r1">确认密码</div>
                <el-form-item prop="confirmPassword">
                  <el-input class="form-input" type="password" v-model="registerForm.confirmPassword" placeholder="确认密码" show-password />
                </el-form-item>
              </div>

              <el-form-item prop="captcha">
                <div class="captcha-url">
                  <el-input v-model="registerForm.captcha" placeholder="请输入图形验证码" />
                  <img :src="captchaSrc" @click="refreshCaptcha" class="images" />
                </div>
              </el-form-item>

              <el-form-item class="form-item">
                <el-button class="but but-c" type="primary" @click="doRegister">注册</el-button>
                <el-button class="but" @click="cancelRegister">取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { register } from '../../request/api'

export default {
  data() {
    const checkPass = (rule, value, callback) => {
      if (value !== this.registerForm.password) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      registerForm: {
        phone: '',
        password: '',
        confirmPassword: '',
        captcha: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度不能少于6位', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: checkPass, trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' }
        ]
      },
      captchaSrc: '',
      timestamp: ''
    }
  },
  methods: {
    async doRegister() {
      this.$refs.registerForm.validate(async valid => {
        if (!valid) {
          console.log('error submit!')
          return false
        }
        try {
          const postData = {
            phone: this.registerForm.phone,
            password: this.registerForm.password,
            captcha: this.registerForm.captcha,
            timestamp: this.timestamp,
          }
          const res = await register(postData)
          if (res.status !== 200 || res.data.code !== '1000') {
            this.$message.error(res.data.msg)
            return
          }

          this.$message.success('注册成功！')
          this.$router.push('/aiLogin')
        } catch (error) {
          this.$message.error('注册请求失败');
        }
      })
    },
    cancelRegister() {
      this.$router.push('/aiLogin')
    },
    // 刷新验证码
    async refreshCaptcha() {
      this.timestamp = new Date().getTime()
      this.captchaSrc = `https://www.bmbai.top/api/shopApi/getCaptchaUrl?timestamp=${ this.timestamp }`
    }
  },
  mounted() {
    this.timestamp = new Date().getTime()
    this.captchaSrc = `https://www.bmbai.top/api/shopApi/getCaptchaUrl?timestamp=${ this.timestamp }`
  }
}
</script>

<style lang="less" scoped>
.register {
  height: 100%;
  background-image: url(../../assets/image/loginBackground.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-main {
    height: 640px;
    width: 1100px;
    background-color: #fff;
    border-radius: 30px;
    display: flex;
    .main-left {
      height: 640px;
    }
    .main-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .right-center {
        height: 500px;
        width: 400px;
        display: flex;
        flex-direction: column;
        .login-form {
          height: 400px;
          .form-div {
            display: flex;
            line-height: 40px;
            .m-r1 {
              margin-right: 10px;
            }
            .m-r2 {
              margin-right: 26px;
            }
            .m-r3 {
              margin-right: 44px;
            }
            .form-title {
              font-size: 18px;
              color: #808b94;
            }
            .form-input {
              width: 300px;
            }
          }

          .captcha-url {
            display: flex;
            .images {
              border: 1px solid #dcdfe6;
            }
          }
          .but {
            width: 150px;
            border-radius: 20px;
            font-size: 18px;
          }
          .but-c {
            background-color: #4b71ff;
            &:hover {
              background-color: #6586fc;
            }
          }
        }
        .login-title {
          height: 70px;
          .title-ai {
            font-size: 30px;
            color: #4b71ff;
          }
        }
      }
    }
  }
}
</style>